@import "main.scss";

#navbar {
  opacity: 0.95;
}

.is-active {
  font-weight: 600;
}

.navbar-dropdown {
  border: none;
  right: 0;
  left: auto;
}

a.navbar-item:hover,
.navbar-link:hover {
  background-color: rgba($color: #fff, $alpha: 0.25) !important;
  color: white !important;
}

a.navbar-item.is-active {
  background-color: rgba($color: #fff, $alpha: 0.25) !important;
  color: white !important;

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.25) !important;
    color: grey;
  }
}

.navbar-link:not(.is-arrowless)::after {
  border-color: grey;
}


#hero {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.75) 100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.75) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);

  &::before {
    filter: blur(0.15rem);
    background-image: url(/assets/images/media-2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    opacity: 0.25;
    content: "";
  }
}

.title {
  margin: auto 0.75rem !important;
}

.subtitle {
  letter-spacing: 0.25rem;
}

#about {
  .image {
    max-width: 20vmax;
    margin: auto;
  }

  a {
    min-width: 25vmax;
    margin: 0.5rem;
  }
}

#booking {
  #contact-button {
    margin: 0.5rem auto;
    display: flex;
    justify-content: center;
  }

  svg {
    transition: all 0.15s ease-in-out;
    margin: auto 0.75vmax;

    &:hover {
      transform: scale(1.10);
      filter: opacity(0.75);
    }
  }

  hr {
    background-color: black;
  }
}

#blog {
  .hero-body {
    padding: 3rem 0.25rem 1rem 0.25rem;
  }

  .container {
    margin: 1.5rem auto;
  }

  #blog-card {
    transition: all 0.25s ease;
    margin-bottom: 0.5vmax;
    transform: scale(0.85);
    margin-right: 2.5vmax;
    margin-left: 2.5vmax;
    line-height: normal;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: scale(0.875);
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 768px) {
      transform: scale(1);
      margin-bottom: 5vmax !important;

      &:hover {
        transform: scale(1.025);
        margin-bottom: 5vmax !important;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
      }

      .image {
        padding-top: 50% !important;
      }
    }

    .title {
      margin: 0.5rem !important;
    }

    .content {
      margin: 0.5rem;
    }

    .column {
      display: grid;

      .image {
        border-radius: 0.25rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

#search {
  border-bottom: 0.15rem solid;

  input {
    border: none;
    outline: none;
    font-weight: 500 !important;

    &::placeholder {
      font-weight: 500;
      color: grey;
      letter-spacing: 0.25rem;
    }
  }

  .button {
    transition: all 0.25s ease;

    &:focus {
      outline: 0.25rem solid white;
    }

    border: none;
  }
}


#footer {
  #backtotop {
    transition: all 0.25s ease;
    width: 10rem;
    padding: 0.25rem;
    margin: auto auto 1rem auto;

    &:hover {
      transform: scale(1.05);
    }
  }

  .container {
    margin: auto;
    padding: 1.5rem;
    line-height: 1.25;

    .column {
      display: grid;
      justify-content: center;

      li {
        list-style: circle;
        list-style-position: outside;
      }

      a {
        color: #7a7a7a;

        &:hover {
          color: white;
        }
      }

      figure {
        border: 1.5px solid white;
        border-radius: 50rem;
        justify-content: center;
        margin: auto;
        transition: all 0.25s ease;

        &:hover {
          transform: scale(1.15);
        }
      }
    }
  }

  #credits {
    padding: 0.75rem;
    font-size: 0.85rem;

    a {
      text-decoration: underline;
      font-weight: bolder;
      color: white;
    }
  }
}

#events {
  .columns {
    .column {
      border-radius: 0.5rem;

      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  #event-card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    transform: scale(0.95);
    transition: all 0.5s ease;
    margin-bottom: 0.5rem !important;
    display: inline-table;

    &:hover {
      transform: scale(0.975);
    }
  }

  .card {
    transition: all 0.25s ease;
    background-color: #000000;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.90) !important;
    }
  }
}

#project {
  .columns {
    .column {
      border-radius: 0.5rem;

      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  #project-card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    transform: scale(0.95);
    transition: all 0.5s ease;
    margin-bottom: 0.5rem !important;
    display: inline-table;

    &:hover {
      transform: scale(0.975);
    }
  }

  .card {
    transition: all 0.25s ease;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.90) !important;
    }
  }
}

#post {
  @media (min-width: 768px) {
    margin: auto 2.5vmax;
  }

  a {
    text-decoration: underline;
  }

  #title {
    text-decoration: none;
  }

  .title {
    margin: auto;
  }

  #post-image {
    border-radius: 0.25rem;
  }

  .subtitle {
    letter-spacing: 0.05rem;
  }

  #description {
    margin-bottom: 1.25vmax;
  }

  .tag {
    margin: 0.15rem auto;
  }

  .content {

    ol,
    ul,
    blockquote {
      display: inline-block;
    }

    hr {
      background-color: grey;
    }

    img,
    iframe {
      max-width: calc(100% - 25vw);
      margin: 0.25rem auto;

      @media (max-width: 768px) {
        max-width: calc(100% - 5vw);
      }
    }

    ul,
    ol {
      display: inline-block;
    }
  }

  .container {
    margin: 2.5rem auto;
    word-break: break-word;
    max-width: -webkit-fill-available;
  }

  #disqus_thread {
    margin-top: 5vmax;
  }
}

#page-section {
  .content {

    ul,
    ol,
    blockquote {
      display: inline-block;
    }
  }
}