@charset "utf-8";
@import url('https://cdn.jsdelivr.net/npm/bulma@0.8.2/css/bulma.min.css');


html,
body {
  font-family: 'raleway', sans-serif;
  scroll-behavior: smooth !important;
  transition: all 0.25s ease;
}



/* width */
::-webkit-scrollbar {
  width: 0.5vmax !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: white !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #363636 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black !important;
}

::placeholder {
  font-family: 'Poppins', sans-serif;
}

#navbar {
  .navbar-burger {
    span:nth-child(2) {
      left: calc(50%);
      width: 8px;
    }
  }
}

.is-active {
  font-weight: bold;
}

.columns {
  .button {
    margin: 0.5rem !important;
  }
}

hr {
  margin: 1rem auto;
  height: 1px;
}

.column {
  margin: auto 0.5rem;
  padding: 0.5rem;
}

.searchResult {
  a {
    .title {
      text-decoration: underline;
    }
  }
}